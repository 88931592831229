/* ------------------------------------------------------------ *\
	Video Items
\* ------------------------------------------------------------ */

.video-items {

	&:last-child {
		margin-bottom: -49px;
	}

	[class^="col-"] {

		&:nth-child(odd) {
			clear: both;
		}
	}
}

.video-item {
	margin-bottom: 49px;
	text-align: center;

	.video {

		&-image {

			a {
				position: relative;
				display: block;

				&:hover {

					&:before {
						transform: scale(1.1);
					}
				}

				&:before,
				&:after {
					position: absolute;
					top: 50%;
					left: 50%;
					content: '';
				}

				&:before {
					width: 80px;
					height: 80px;
					border-radius: 50%;
					margin: -40px 0 0 -40px;
					background: $color-beige;
					transition: transform .4s;
				}

				&:after {
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 10px 0 10px 14px;
					border-color: transparent transparent transparent $color-light;
					margin: -10px 0 0 -7px;
				}
			}

			img {
				width: 100%;
			}
		}

		&-content {
			padding: 25px 0;

			h3 {
				margin-bottom: 19px;
				color: inherit;
				letter-spacing: 0.05em;
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.video-item {

		.video {

			&-image {
				
				a {

					&:before {
						width: 60px;
						height: 60px;
						margin: -30px 0 0 -30px;
					}
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.video-items {

		&:last-child {
			margin-bottom: -20px;
		}
	}

	.video-item {
		margin-bottom: 20px;

		.video {

			&-content {
				padding: 20px 0;
			}
		}
	}
}
