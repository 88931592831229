body.page-template-form-landing {
  background-image: url('/wp-content/uploads/bg-new.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #1b2a2d;
}
.form-landing {
  width: 100%;
  max-width: 1030px;
  margin: 75px auto 75px auto;
  padding: 0 30px;
  color: #fff;
}

.intro-bar {
  font-size: 20px;
  padding: 20px 0;
  text-align: center;
  background-color: #000000;
  color: #fff;
}

.form-intro {
  text-align: center;
  margin-bottom: 50px;
}

.form-intro h1 {
  color: #fff;
  margin-bottom: 10px;
}

.form-intro h2 {
  color: #fff;
}

.form-basic {
  margin-top: -30px
}

.form-basic .gform_wrapper label.gfield_label {
  font-weight: 400;
  margin-bottom: 0;
}

body .form-basic .gform_wrapper ul li.gfield + li.gfield {
  margin-top: 15px !important;
}

body .form-basic .gform_wrapper .top_label div.ginput_container {
  margin-top: 0;
}

.form-basic label {
  font-weight: 400;
}

.form-basic .gform_footer {
  margin-top: 0;
}

.form-basic .gform_footer .gform_button {
  height: 50px;
  border: none;
  border-radius: 4px;
  padding: 10px 25px !important;
  width: 100%;
  background-color: #8e784a;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
}

.form-footnote {
  margin-top: 25px;
  color: rgba(255, 255, 255, 0.5);
}

.form-basic .gform_wrapper input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
  border-radius: 4px;
  padding: 8px 15px;
  height: 50px;
  color: #000;
  border: 0;
}

@media (max-width: 767px) {
  .intro-bar {
    font-size: 20px;
  }
  .form-basic {
    margin-bottom: 30px;
  }
}