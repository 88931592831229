/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$font-size-base:					17px;
$line-height-base:					1.59;

$font-size-base-small:				14px;
$line-height-base-small:			1.43;

$shell-max-width:					1170px;

$color-info:						#e5f7f9;
$color-light-solid:					#f2f0f1;
$color-solid:						#e5e3e4;
$color-primary:						#01b3c4;
$color-text:						#3c3c3b;
$color-danger:						#ff4600;
$color-warning:						#ffeee9;
$color-dark: 						#000000;
$color-light: 						#ffffff;
$color-beige: 						#9d855b;
$color-darkgrey: 					#232323;
$color-grey: 						#aaaaaa;
$color-lightgrey: 					#eeeeee;
$color-lightergrey:					#dddddd;
$color-darkblue:					#19262a;
$color-darkerblue:					#111d21;
$color-green:						#4ac189;


$breakpoint-large-desktop:			1599px;
$breakpoint-small-desktop:			1199px;
$breakpoint-tablet-portrait:		991px;
$breakpoint-mobile: 				767px;
$breakpoint-mobile-portrait: 		374px;

$font-family-sans-serif:			'Arial', 'Helvetica', sans-serif;
$font-family-serif:					'Times', serif;
$font-avenir: 						'Avenir', sans-serif;
$font-tungsten: 					'Tungsten', sans-serif;
$font-proxima-nova: 				'Proxima Nova', sans-serif;

/*  Bootstrap Grid  */
$grid-gutter-width: 30px !default;
$container-large-desktop: (1170px + $grid-gutter-width) !default;
