/* ------------------------------------------------------------ *\
	Popup
\* ------------------------------------------------------------ */

.popup {
	position: relative;
	max-width: 912px;
	padding: 44px 15px;
	margin: 46px auto;
	background: $color-light;

	&-dark {

		&.mfp-bg {
			background: #1c2a2e;
			opacity: .9;
		}

		.mfp-close {
			position: absolute;
			top: -46px;
			left: 50%;
			width: 44px;
			padding: 0;
			margin-left: -20px;
			font-family: $font-avenir;
			font-size: 30px;
			color: $color-light;
			opacity: .5;
			text-align: center;
			transition: opacity .4s;

			&:hover {
				opacity: 1;
			}
		}

		.mfp-iframe-holder {

			.mfp-close {
				top: -49px;
			}

			.mfp-content {
				max-width: 830px;
			}
		}
	}

	.popup {

		&-content {
			text-align: center;

			h3 {
				font-size: 35px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Popup Primary
\* ------------------------------------------------------------ */

.popup-primary {
	padding-bottom: 48px;

	.row {
		position: relative;
		
		&:before {
			position: absolute;
			top: 17px;
			left: 50%;
			bottom: 12px;
			display: block;
			border-left: 1px solid #cccccc;
			margin-left: -1px;
			content: '';
		}
	}

	.popup-inner {
		padding: 0 27px 0 37px;
	}

	.popup-content {
		padding: 10px 20px 0 25px;

		.list-steps {
			padding: 0 30px;
			margin-bottom: 32px;
		}

	  .uk-buyer {
		margin-top: 15px;
		font-size: 14px;

		p {
		  font-size: 12px;
		}

		img {
		  position: relative;
		  top: -3px;
		  margin-right: 10px;
		}
	  }
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.popup-primary {

		.popup-inner {
			padding: 0;
		}

		.popup-content {
			padding: 10px 0 0;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.popup-primary {

		.row {

			&:before {
				display: none;
			}

			[class^="col-"] {

				& ~ [class^="col-"] {
					margin-top: 30px;
				}
			}
		}

		.popup-content {

			h3 {
				margin-bottom: 20px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Popup Secondary
\* ------------------------------------------------------------ */

.popup-secondary {
	position: relative;
	max-width: 494px;
	padding: 63px 50px 65px;
	text-align: center;

	.mfp-close {
		top: 8px;
		right: 7px;
		left: auto;
		font-size: 0;
		color: #777777;
		opacity: 1;
		transition: transform .4s;

		&:hover {
			transform: rotate(-90deg);
		}

		&:before,
		&:after {
			position: absolute;
			top: 8px;
			left: 20px;
			width: 2px;
			height: 30px;
			background: #777;
			content: '';
		}

		&:before {
			transform: rotate(-45deg);
		}

		&:after {
			transform: rotate(45deg);
		}
	}

	h6 {
		margin-bottom: 38px;
		line-height: 1.5;
	}

	.form-report {
		
		.gform_footer,
		.gform_body {
			max-width: 304px;
			margin: 0 auto;
		}
	}
}

#intercom-container {
  display: none;
}

.page-template-template-findadvisor #intercom-container {
  display: block;
}

#freebook .mfp-close {
	display: none !important;
}

.gold-popup .customx {
	position: absolute;
	display: block;
	top: 20px;
	right: 20px;
	height: 18px;
	width: 19px;
	background-image: url('/wp-content/uploads/whitex2x.png');
	background-size: 19px 18px;
	cursor: pointer;
}

.gold-popup {
	position: relative;
	max-width: 578px;
	width: 100%;
	margin: 0 auto;
	padding: 65px 25px 45px;
	background: #9d855b;
	color: #fff;

	.validation_error {
		display: none !important;
	}

	.gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
		border: 0;
		background: transparent;
		margin: 0 !important;
		max-width: 100% !important;
	}

	.gfield_description.validation_message {
		font-weight: 400;
		padding: 5px 0 0 0;
		text-align: center;
	}
}

.gold-popup h2 {
	font-size: 50px;
	line-height: 60px;
	color: #fff;
	margin-bottom: 14px;
}

.gp-container {
	max-width: 390px;
	margin: 0 auto;
	width: 100%;
	text-align: center;
}

.popup-form .gfield_label {
	display: none !important;
}

.popup-form .gform_wrapper ul.gform_fields li.gfield {
	padding:0;
}

.popup-form .gform_wrapper ul.gform_fields li.gfield input[type="text"] {
	color: #fff;
	background: #9d855b;
	border: 1px solid #fff;
	text-align: center;
	width: 310px;
	height: 40px;
	margin: 0 auto;
	display: block;
	font-size: 13px;
}

.popup-form .gform_wrapper .gform_footer {
	text-align: center;
	padding: 0;
}

.popup-form .gform_wrapper .gform_footer .gform_button {
	display: block;
	text-transform: uppercase;
	background: #fff;
	color: #9d855b;
	border: 1px solid #fff;
	margin: 0 auto;
	width: 195px;
	height: 40px;
	transition: all 0.3s ease;
	font-size: 13px;
}

.popup-form .gform_wrapper .gform_footer .gform_button:hover {
	color: #fff;
	background: #9d855b;
}