/* ------------------------------------------------------------ *\
	Testimonial
\* ------------------------------------------------------------ */

.testimonial {

	blockquote {
		padding: 0;
		border: none;
		margin: 0;
		text-align: center;

		p {
			margin-bottom: 60px;
			font-size: 22px;
			line-height: 1.4;
			color: $color-beige;
			font-weight: 900;
		}

		h3 {
			margin-bottom: 5px;
			letter-spacing: 0.05em;
		}

		> span {
			display: block;
			font-size: 14px;
			color: $color-grey;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.testimonial {

		blockquote {

			p {
				margin-bottom: 30px;
				font-size: 20px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Testimonial Secondary
\* ------------------------------------------------------------ */

.testimonial-secondary {
	margin-bottom: 39px;

	blockquote {
		padding: 0;
		border: none;

		p {
			margin-bottom: 35px;
			font-size: 32px;
			line-height: 1.32;
			font-style: italic;
		}

		> span {
			font-size: 20px;
			font-weight: 900;
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.testimonial-secondary {

		blockquote {

			p {
				font-size: 26px;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.testimonial-secondary {
		margin-bottom: 25px;

		blockquote {

			p {
				margin-bottom: 25px;
				font-size: 20px;
			}

			> span {
				display: block;
				font-size: 16px;
			}
		}
	}
}
