/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

  Mixins available:
    -   css3-prefix             - arguments: Property, Value
    -   background-gradient     - arguments: Start Color: #3C3C3C, End Color: #999999
    -   background-horizontal   - arguments: Start Color: #3C3C3C, End Color: #999999
    -   background-radial       - arguments: Start Color: #FFFFFF, Start position: 0%, End Color: #000000, End position: 100%
    -   background-size         - arguments: Width: 100%, Height: 100%
    -   background-opacity      - arguments: Color: #000, Opacity: .85
    -   border-radius           - arguments: Radius: 5px
    -   border-radius-separate  - arguments: Top Left: 5px, Top Right: 5px, Bottom Left: 5px, Bottom Right: 5px
    -   box                     - arguments: Orientation: horizontal, Pack: center, Align: center
    -   box-rgba                - arguments: R: 60, G: 3, B: 12, Opacity: 0.23, Color: #3C3C3C
    -   box-shadow              - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
    -   box-sizing              - arguments: Type: border-box
    -   columns                 - arguments: Count: 3, Gap: 10
    -   double-borders          - arguments: Color One: #3C3C3C, Color Two: #999999, Radius: 0
    -   flex                    - arguments: Value: 1
    -   flip                    - arguments: ScaleX: -1
    -   font-face               - arguments: Font Family: myFont, Eot File Src: myFont.eot, Woff File Src: myFont.woff, Ttf File Src: myFont.ttf
    -   opacity                 - arguments: Opacity: 0.5
    -   outline radius          - arguments: Radius: 5px
    -   resize                  - arguments: Direction: both
    -   rotate                  - arguments: Degree: 0, M11: 0, M12: 0, M21: 0, M22: 0
    CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
    -   text-shadow             - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
    -   transform               - arguments: Parameters: null
    -   transform-style         - arguments: Style: preserve-3d
    -   transition              - Default arguments: What: all, Length: 1s, Easing: ease-in-out
    -                            - Examples: @include transition (all 2s ease-in-out);
    -                                        @include transition (opacity 1s ease-in 2s, width 2s ease-out);
    -   triple-borders          - arguments: Color One: #3C3C3C, Color Two: #999999, Color Three: #000000, Radius: 0
    -   keyframes               - arguments: Animation name
                                - content:   Animation css
    -   animation               - arguments: name duration timing-function delay iteration-count direction fill-mode play-state
                                             (http://www.w3schools.com/cssref/css3_pr_animation.asp)

------------------------------------------------------------- */

//Clearfix
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
//uage:  @include clearfix;

//Media  queries
@mixin breakpoint-large-desktop {
  @media (max-width: #{$breakpoint-large-desktop}) {
    @content;
  }
}

@mixin breakpoint-small-desktop {
  @media (max-width: #{$breakpoint-small-desktop}) {
    @content;
  }
}

@mixin breakpoint-tablet-portrait {
  @media (max-width: #{$breakpoint-tablet-portrait}) {
    @content;
  }
}

@mixin breakpoint-mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin breakpoint-mobile-portrait {
  @media (max-width: #{$breakpoint-mobile-portrait}) {
    @content;
  }
}

@mixin retina {
  @media
    (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}



// ADDS A BROWSER PREFIX TO THE PROPERTY
@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
   -khtml-#{$property}: #{$value};
     -moz-#{$property}: #{$value};
      -ms-#{$property}: #{$value};
       -o-#{$property}: #{$value};
          #{$property}: #{$value};
}

// BACKGROUND GRADIENT
@mixin background-gradient($startColor: #3C3C3C, $endColor: #999999) {
    background-color: $startColor;
    background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
    background-image: -webkit-linear-gradient(top, $startColor, $endColor);
    background-image:    -moz-linear-gradient(top, $startColor, $endColor);
    background-image:     -ms-linear-gradient(top, $startColor, $endColor);
    background-image:      -o-linear-gradient(top, $startColor, $endColor);
    background-image:         linear-gradient(top, $startColor, $endColor);
    filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}

// BACKGROUND HORIZONTAL
@mixin background-horizontal($startColor: #3C3C3C, $endColor: #999999) {
    background-color: $startColor;
    background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
    background-image: -webkit-linear-gradient(left, $startColor, $endColor);
    background-image:    -moz-linear-gradient(left, $startColor, $endColor);
    background-image:     -ms-linear-gradient(left, $startColor, $endColor);
    background-image:      -o-linear-gradient(left, $startColor, $endColor);
    background-image:         linear-gradient(left, $startColor, $endColor);
    filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}', gradientType='1');
}

// BACKGROUND RADIAL
@mixin background-radial($startColor: #FFFFFF, $startPos: 0%, $endColor: #000000, $endPos:100%) {
    background: -moz-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop($startPos,$startColor), color-stop($endPos,$endColor));
    background: -webkit-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
    background: -o-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
    background: -ms-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
    background: radial-gradient(ellipse at center, $startColor $startPos,$endColor $endPos);
}

// BACKGROUND SIZE
@mixin background-size($width: 100%, $height: $width) {
  @if type-of($width) == 'number' and $height != null {
    @include css3-prefix('background-size', $width $height);
  } @else {
    @include css3-prefix('background-size', $width);
  }
}

// BACKGROUND COLOR OPACITY
@mixin background-opacity($color: #000, $opacity: 0.85) {
  background: $color;
  background: rgba($color, $opacity);
}

// BORDER RADIUS
@mixin border-radius($radius: 5px) {
    @include css3-prefix('border-radius', $radius);
}

@mixin border-radius-separate($topLeftRadius: 5px, $topRightRadius: 5px, $bottomLeftRadius: 5px, $bottomRightRadius: 5px) {
  -webkit-border-top-left-radius:     $topLeftRadius;
  -webkit-border-top-right-radius:    $topRightRadius;
  -webkit-border-bottom-right-radius: $bottomRightRadius;
  -webkit-border-bottom-left-radius:  $bottomLeftRadius;

  -moz-border-radius-topleft:     $topLeftRadius;
  -moz-border-radius-topright:    $topRightRadius;
  -moz-border-radius-bottomright: $bottomRightRadius;
  -moz-border-radius-bottomleft:  $bottomLeftRadius;

  border-top-left-radius:     $topLeftRadius;
  border-top-right-radius:    $topRightRadius;
  border-bottom-right-radius: $bottomRightRadius;
  border-bottom-left-radius:  $bottomLeftRadius;
}

// BOX
@mixin box($orient: horizontal, $pack: center, $align: center) {
  display: -webkit-box;
  display: -moz-box;
  display: box;

  @include css3-prefix('box-orient', $orient);
  @include css3-prefix('box-pack', $pack);
  @include css3-prefix('box-align', $align);
}

// BOX RGBA
@mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #3C3C3C) {
  background-color: transparent;
  background-color: rgba($r, $g, $b, $opacity);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}',endColorstr='#{$color}');
            zoom:   1;
}


// BOX SHADOW
@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4), $inset: "") {
  @if ($inset != "") {
    @include css3-prefix('box-shadow', $inset $x $y $blur $color);
  } @else {
    @include css3-prefix('box-shadow', $x $y $blur $color);
  }
}

// BOX SIZING
@mixin box-sizing($type: border-box) {
  @include css3-prefix('box-sizing', $type);
}

// COLUMNS
@mixin columns($count: 3, $gap: 10) {
  @include css3-prefix('column-count', $count);
  @include css3-prefix('column-gap', $gap);
}

// DOUBLE BORDERS
@mixin double-borders($colorOne: #3C3C3C, $colorTwo: #999999, $radius: 0) {
  border: 1px solid $colorOne;

  @include css3-prefix('box-shadow', 0 0 0 1px $colorTwo);

  @include border-radius( $radius );
}

// FLEX
@mixin flex($value: 1) {
  @include css3-prefix('box-flex', $value);
}

// FLIP
@mixin flip($scaleX: -1) {
  @include css3-prefix('transform', scaleX($scaleX));
  filter:            FlipH;
  -ms-filter:        "FlipH";
}

// FONT FACE
@mixin font-face($fontFamily: myFont, $eotFileSrc: 'myFont.eot', $woffFileSrc: 'myFont.woff', $ttfFileSrc: 'myFont.ttf', $svgFileSrc: 'myFont.svg', $svgFontID: '#myFont') {
  font-family: $fontFamily;
  src: url($eotFileSrc)  format('eot'),
       url($woffFileSrc) format('woff'),
       url($ttfFileSrc)  format('truetype'),
       url($svgFileSrc + $svgFontID) format('svg');
}

// OPACITY
@mixin opacity($opacity: 0.5) {
    $opacityMultiplied: ($opacity * 100);

    filter:         alpha(opacity=$opacityMultiplied);
    -ms-filter:     "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacityMultiplied + ")";
    @include css3-prefix('opacity', $opacity);
}


// OUTLINE RADIUS
@mixin outline-radius($radius: 5px) {
  @include css3-prefix('outline-radius', $radius);
}

// RESIZE
@mixin resize($direction: both) {
  @include css3-prefix('resize', $direction);
}

// ROTATE
@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
  @include css3-prefix('transform', rotate($deg + deg));
  filter: progid:DXImageTransform.Microsoft.Matrix(
       M11=#{$m11}, M12=#{$m12}, M21=#{$m21}, M22=#{$m22}, sizingMethod='auto expand');
    zoom: 1;
}

// TEXT SHADOW
@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4)) {
    text-shadow: $x $y $blur $color;
}

// TRANSFORM
@mixin transform($params) {
  @include css3-prefix('transform', $params);
}

// TRANSFORM-ORIGIN
@mixin transform-origin($params) {
  @include css3-prefix('transform-origin', $params);
}

// TRANSFORM STYLE
@mixin transform-style($style: preserve-3d) {
  @include css3-prefix('transform-style', $style);
}

// TRANSITION
@mixin transition($properties...) {

  @if length($properties) >= 1 {
    @include css3-prefix('transition', $properties);
  }

  @else {
    @include css3-prefix('transition',  "all 0.2s ease-in-out 0s");
  }
}

// TRIPLE BORDERS
@mixin triple-borders($colorOne: #3C3C3C, $colorTwo: #999999, $colorThree: #000000, $radius: 0) {
    border: 1px solid $colorOne;

    @include border-radius($radius);

    @include css3-prefix('box-shadow', "0 0 0 1px #{$colorTwo}, 0 0 0 2px #{$colorThree}");
}

// KEYFRAMES
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

// ANIMATION
@mixin animation($str) {
  @include css3-prefix('animation', $str);
}

// CHEVRON
@mixin chevron($dimensions, $borderWidth, $borderColor, $margin, $rotation, $origin, $transitionDuration) { 
    content: ''; 
    width: $dimensions; 
    height: $dimensions; 
    display: inline-block; 
    vertical-align: middle; 
    border-width: 0 0 $borderWidth $borderWidth; 
    border-style: solid; 
    border-color: $borderColor; 
    margin: $margin; 
    transform: rotate($rotation); 
    transform-origin: $origin; 
    transition: all $transitionDuration;
}

// Flexbox Mixins
@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

%flexbox { @include flexbox; }

//----------------------------------

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

%inline-flex { @include inline-flex; }

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}
  // Shorter version:
  @mixin flex-dir($args...) { @include flex-direction($args...); }

//----------------------------------------------------------------------

@mixin flex-wrap($value: nowrap) {
  // No Webkit Box fallback.
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else { 
    -ms-flex-wrap: $value; 
  }
  flex-wrap: $value;
}


@mixin flex-flow($values: (row nowrap)) {
  // No Webkit Box fallback.
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}


@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -moz-order: $int;
  -ms-flex-order: $int;
  order: $int;
}


@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}


@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}


@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}


@mixin flex($fg: 1, $fs: null, $fb: null) {
    
  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}


@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;    
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}
  // Shorter version:
  @mixin flex-just($args...) { @include justify-content($args...); }


@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}


@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  -moz-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}


@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  -moz-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}
