/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.articles {

	[class^="col-"] {

		&:nth-child(3n+1) {
			clear: both;
		}
	}
}

.article {
	margin-bottom: 31px;

	.article {

		&-image {

			img {
				width: 100%;
			}
		}

		&-content {
			padding: 35px 30px;

			h5 {
				min-height: 57px;
				margin-bottom: 24px;
			}

			h4,
			h5 {

				&:hover {

					a {
						color: $color-beige;
						text-decoration: none;
					}
				}

				a {
					transition: color .4s;
				}
			}
			
			.article-meta {
				min-height: 0;
				margin-bottom: 3px;
				color: $color-grey;
				font-weight: 900;
				letter-spacing: 0.1em;
				text-transform: uppercase;
			}
		}

	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.articles {

		[class^="col-"] {

			&:nth-child(3n+1) {
				clear: none;
			}

			&:nth-child(odd) {
				clear: both;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Article Primary
\* ------------------------------------------------------------ */

.article-primary {

	.article {

		&-content {

			p {
				min-height: 82px;
				margin-bottom: 10px;
				font-size: 14px;
			}

			.article-meta {
				min-height: 0;
				margin-bottom: 3px;
				font-size: 13px;
			}
		}

		&-image {

			a {
				overflow: hidden;
				display: block;

				&:hover {

					img {
						transform: scale(1.1);
					}
				}

				img {
					transition: transform .4s;
				}
			}
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.article-primary {

		.article-content {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.article-primary {

		.article-content {
			padding: 15px;

			h5 {
				min-height: 46px;
				margin-bottom: 15px;
			}

			p {
				min-height: 0;
				margin-bottom: 15px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Article Secondary
\* ------------------------------------------------------------ */

.article-secondary {
	text-align: center;

	p {
		text-align: left;
	}

	.article {

		&-image {
			margin-bottom: 49px;
		}

		&-content {
			padding: 0;

			h2 {
				margin-bottom: 81px;
			}

			h4 {
				margin-bottom: 62px;
			}

			p {

				& + h2 {
					padding-top: 64px;
				}
			}

			.article-meta {
				margin-bottom: 15px;
				font-size: 16px;
				text-align: center;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.article-secondary {

		.article {

			&-image {
				margin-bottom: 30px;
			}

			&-content {

				h2 {
					margin-bottom: 47px;
				}
				
				h4 {
					margin-bottom: 30px;

					br {
						display: none;
					}
				}

				p {

					& + h2 {
						padding-top: 20px;
					}
				}
			}
		}
	}
}
