/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {

	.gfield_label {
		display: none;
	}

	.gform_fields {
		list-style: none outside none;
	}

	.gfield {
		margin-bottom: 19px;
	}

	.ginput_container_select {
		position: relative;

		&:after {
			position: absolute;
			top: 19px;
			right: 19px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 6px 5.5px 0 5.5px;
			border-color: $color-beige transparent transparent transparent;
			pointer-events: none;
			content: '';
		}
	}

	input[type="text"], 
	input[type="search"], 
	input[type="email"], 
	input[type="password"], 
	input[type="tel"],
	select,
	textarea {
		display: block;
		width: 100%;
		padding: 7px 20px 6px;
		border-radius: 2px;
		border: 2px solid $color-lightergrey;
		font-size: 17px;
		color: #777777;
		-webkit-appearance: none; 
		-moz-appearance: none; 
		appearance: none; 
	}

	select {
		padding-right: 30px;
		cursor: pointer;
	}

	input[type="submit"] {
		display: block;
		width: 100%;
		padding: 10px 5px;
		border: 2px solid $color-beige;
		background: $color-beige;
		font-size: 13px; 
		line-height: 1.3; 
		color: $color-light; 
		font-weight: 900; 
		letter-spacing: 0.1em; 
		text-transform: uppercase;
		transition: border .4s, background .4s, color .4s;

		&:focus,
		&:hover {
			border-color: $color-beige;
			background: none;
			color: $color-beige;
			outline: none;
		}
	}
}

/* ------------------------------------------------------------ *\
	Form Report
\* ------------------------------------------------------------ */

.form-report {

	.gform_heading {
		margin-bottom: 38px;
	}

	.gform_title {
		margin-bottom: 0;
		font-family: $font-avenir;
		font-size: 20px;
		line-height: 1.5;
		color: inherit;
		text-transform: none;
	}

	.gform_description {
		font-size: 20px;
		line-height: 1.5;
		font-weight: bold;
	}
}
