/* ------------------------------------------------------------ *\	
	Btns
\* ------------------------------------------------------------ */

.btn {
	display: inline-block; 
	vertical-align: top; 
	text-align: center; 
	white-space: normal; 
	vertical-align: middle;
	-webkit-appearance: none; 
	-moz-appearance: none; 
	appearance: none; 
	transition: border .4s, background .4s, color .4s, opacity .4s, transform .4s;
}

.btn {
	
	&:hover,
	&:focus {
		color: inherit; 
		text-decoration: none; 
		outline: none;
	}
}

/*  Btn Primary  */
.btn-primary {
	min-width: 150px;
	height: auto; 
	padding: 10px 15px; 
	border-radius: 0; 
	border: 2px solid $color-beige; 
	background: $color-beige; 
	font-size: 13px; 
	line-height: 1.3; 
	color: $color-light; 
	font-weight: 900; 
	letter-spacing: 0.1em; 
	text-transform: uppercase;

	&:active,
	&:active:focus,
	&:active:hover,
	&:focus,
	&:hover {
		border-color: $color-beige;
		background: none;
		color: $color-beige;
		outline: none;
	}
}

/*  Btn Green  */
.btn-green {
	border-color: $color-green;
	background: $color-green;

	&:active,
	&:active:focus,
	&:active:hover,
	&:focus,
	&:hover {
		border-color: $color-green;
		color: $color-green;
	}
}

/*  Btn Transparent  */
.btn-transparent {
	background: none;
	color: $color-beige;

	&:active,
	&:active:focus,
	&:active:hover,
	&:focus,
	&:hover {
		background: $color-beige;
		color: $color-light;
	}
}

/*  Btn Medium  */
.btn-medium {
	min-width: 254px;
}

/*  Btn Large  */
.btn-large {
	padding-left: 37px;
	padding-right: 37px;
}

/*  Btn App  */
.btn-app {
	width: 149px;
	height: 53px;
	padding: 0;
	border: none;
	background: url(../images/btn-appstore@2x.png) no-repeat 0 0;
	-webkit-background-size: 100% 100%;
	background-size: 100% 100%;

	&:active,
	&:active:focus,
	&:active:hover,
	&:focus,
	&:hover {
		background-image: url(../images/btn-appstore@2x.png);
		box-shadow: none;
		outline: none;
	}

	&:hover {
		opacity: .8;
	}
}

/*  Btn Expand  */
.btn-expand {
	position: relative;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background: $color-dark;

	&:after {
		position: absolute;
		top: 6px;
		left: 9px;
		@include chevron(12px, 2px, $color-light, 0 0 0 0, -45deg, 50% 50%, .4s);
	}

	&.active {

		&:after {
			top: 11px;
			transform: rotate(135deg);
		}
	}
}

/*  Btn Play  */
.btn-play {
	position: relative;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: $color-green;
	box-shadow: 0 0 0 12px rgba(255, 255, 255, 0.4);

	&:after {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 13px 0 13px 16px;
		border-color: transparent transparent transparent $color-light;
		margin: -13px 0 0 -6px;
		content: '';
		transition: transform .4s;
	}

	&:active,
	&:active:focus,
	&:active:hover,
	&:focus,
	&:hover {
		box-shadow: 0 0 0 12px rgba(255, 255, 255, 0.4);
		transform: scale(1.1);

		&:after {
			transform: scale(.9);
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.btn-primary {
		min-width: 136px;
		padding: 9px 15px;
		font-size: 11px;
	}
}
