/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	overflow: hidden; 
	display: inline-block; 
	max-width: 100%; 
	width: 241px; 
	height: 38px;
	background: url(../images/logo@2x.png) no-repeat 0 0;
	-webkit-background-size: 100% 100%; 
	background-size: 100% 100%;
	font-size: 0; 
	text-indent: 100%; 
	white-space: nowrap; 
	vertical-align: middle; 
	transition: all .4s;
}

/* ------------------------------------------------------------ *\
	Logo Large
\* ------------------------------------------------------------ */

.logo-large {
	width: 331px;
	height: 55px;
}

/* Mobile */

@include breakpoint-mobile {
	.logo-large {
		width: 241px;
		height: 38px;
	}
}
