/* ------------------------------------------------------------ *\	
	Socials
\* ------------------------------------------------------------ */

.socials {
	text-align: center;

	ul {
		font-size: 0;
		list-style: none outside none;
	}

	li {
		display: inline-block;
		margin: 0 7px;
		vertical-align: middle;
	}

	a {
		position: relative;
		display: block;
		width: 35px;
		height: 35px;
		border-radius: 50%;
		background: $color-beige;
		transition: opacity .4s;

		&:hover {
			opacity: .8;
		}
	}

	[class^="ico-"] {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

/* ------------------------------------------------------------ *\
	Socials Secondary
\* ------------------------------------------------------------ */

.socials-secondary {

	li {
		margin: 0 4px;
	}

	a {
		width: 30px;
		height: 30px;
		background: #cccccc;
	}
}

/* ------------------------------------------------------------ *\
	Socials Tertiary
\* ------------------------------------------------------------ */

.socials-tertiary {
	max-width: 56px;
	padding: 4px 3px;
	background: $color-light;

	ul {
		border: 2px solid $color-beige;
	}

	li {
		display: block;
		margin: 0;

		& ~ li {
			border-top: 2px solid $color-beige;
		}
	}

	a {
		width: 46px;
		height: 47px;
		border-radius: 0;
		background: none;
	}

	small {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		font-size: 10px;
		color: $color-beige;
		text-align: center;
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.socials-tertiary {
		max-width: none;
		display: flex;
		justify-content: center;

		ul {
			font-size: 0;
		}

		li {
			display: inline-block;
			vertical-align: top;

			& ~ li {
				border-top: none;
				border-left: 2px solid $color-beige;
			}
		}
	}
}
