.ico-facebook-beige { background: url(../images/sprite/ico-facebook-beige.png) no-repeat 0 0; background-size: 100% 100%; width: 10px; height: 20px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-facebook-white { background: url(../images/sprite/ico-facebook-white.png) no-repeat 0 0; background-size: 100% 100%; width: 8px; height: 14px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-facebook { background: url(../images/sprite/ico-facebook.png) no-repeat 0 0; background-size: 100% 100%; width: 10px; height: 20px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-googleplus-beige { background: url(../images/sprite/ico-googleplus-beige.png) no-repeat 0 0; background-size: 100% 100%; width: 15px; height: 14px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-googleplus-white { background: url(../images/sprite/ico-googleplus-white.png) no-repeat 0 0; background-size: 100% 100%; width: 15px; height: 14px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-googleplus { background: url(../images/sprite/ico-googleplus.png) no-repeat 0 0; background-size: 100% 100%; width: 15px; height: 14px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-instagram-beige { background: url(../images/sprite/ico-instagram-beige.png) no-repeat 0 0; background-size: 100% 100%; width: 16px; height: 16px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-instagram-white { background: url(../images/sprite/ico-instagram-white.png) no-repeat 0 0; background-size: 100% 100%; width: 16px; height: 16px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-instagram { background: url(../images/sprite/ico-instagram.png) no-repeat 0 0; background-size: 100% 100%; width: 16px; height: 16px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-linkedin-beige { background: url(../images/sprite/ico-linkedin-beige.png) no-repeat 0 0; background-size: 100% 100%; width: 16px; height: 16px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-linkedin-white { background: url(../images/sprite/ico-linkedin-white.png) no-repeat 0 0; background-size: 100% 100%; width: 13px; height: 13px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-linkedin { background: url(../images/sprite/ico-linkedin.png) no-repeat 0 0; background-size: 100% 100%; width: 16px; height: 16px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-mail-beige { background: url(../images/sprite/ico-mail-beige.png) no-repeat 0 0; background-size: 100% 100%; width: 20px; height: 14px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-mail-white { background: url(../images/sprite/ico-mail-white.png) no-repeat 0 0; background-size: 100% 100%; width: 20px; height: 14px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-mail { background: url(../images/sprite/ico-mail.png) no-repeat 0 0; background-size: 100% 100%; width: 20px; height: 14px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-pinterest-beige { background: url(../images/sprite/ico-pinterest-beige.png) no-repeat 0 0; background-size: 100% 100%; width: 14px; height: 18px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-pinterest-white { background: url(../images/sprite/ico-pinterest-white.png) no-repeat 0 0; background-size: 100% 100%; width: 14px; height: 18px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-pinterest { background: url(../images/sprite/ico-pinterest.png) no-repeat 0 0; background-size: 100% 100%; width: 14px; height: 18px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-quote { background: url(../images/sprite/ico-quote.png) no-repeat 0 0; background-size: 100% 100%; width: 30px; height: 25px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-twitter-beige { background: url(../images/sprite/ico-twitter-beige.png) no-repeat 0 0; background-size: 100% 100%; width: 16px; height: 13px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-twitter-white { background: url(../images/sprite/ico-twitter-white.png) no-repeat 0 0; background-size: 100% 100%; width: 12px; height: 9px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-twitter { background: url(../images/sprite/ico-twitter.png) no-repeat 0 0; background-size: 100% 100%; width: 16px; height: 13px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-vimeo-beige { background: url(../images/sprite/ico-vimeo-beige.png) no-repeat 0 0; background-size: 100% 100%; width: 14px; height: 12px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-vimeo-white { background: url(../images/sprite/ico-vimeo-white.png) no-repeat 0 0; background-size: 100% 100%; width: 14px; height: 12px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-vimeo { background: url(../images/sprite/ico-vimeo.png) no-repeat 0 0; background-size: 100% 100%; width: 14px; height: 12px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-youtube-beige { background: url(../images/sprite/ico-youtube-beige.png) no-repeat 0 0; background-size: 100% 100%; width: 15px; height: 15px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-youtube-white { background: url(../images/sprite/ico-youtube-white.png) no-repeat 0 0; background-size: 100% 100%; width: 15px; height: 15px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-youtube { background: url(../images/sprite/ico-youtube.png) no-repeat 0 0; background-size: 100% 100%; width: 15px; height: 15px; display: inline-block; vertical-align: middle; font-size: 0; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.ico-facebook-beige { background: url(../images/sprite/ico-facebook-beige@2x.png) no-repeat 0 0; width: 10px; height: 20px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-facebook-white { background: url(../images/sprite/ico-facebook-white@2x.png) no-repeat 0 0; width: 8px; height: 14px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-facebook { background: url(../images/sprite/ico-facebook@2x.png) no-repeat 0 0; width: 10px; height: 20px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-googleplus-beige { background: url(../images/sprite/ico-googleplus-beige@2x.png) no-repeat 0 0; width: 15px; height: 14px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-googleplus-white { background: url(../images/sprite/ico-googleplus-white@2x.png) no-repeat 0 0; width: 15px; height: 14px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-googleplus { background: url(../images/sprite/ico-googleplus@2x.png) no-repeat 0 0; width: 15px; height: 14px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-instagram-beige { background: url(../images/sprite/ico-instagram-beige@2x.png) no-repeat 0 0; width: 16px; height: 16px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-instagram-white { background: url(../images/sprite/ico-instagram-white@2x.png) no-repeat 0 0; width: 16px; height: 16px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-instagram { background: url(../images/sprite/ico-instagram@2x.png) no-repeat 0 0; width: 16px; height: 16px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-linkedin-beige { background: url(../images/sprite/ico-linkedin-beige@2x.png) no-repeat 0 0; width: 16px; height: 16px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-linkedin-white { background: url(../images/sprite/ico-linkedin-white@2x.png) no-repeat 0 0; width: 13px; height: 13px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-linkedin { background: url(../images/sprite/ico-linkedin@2x.png) no-repeat 0 0; width: 16px; height: 16px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-mail-beige { background: url(../images/sprite/ico-mail-beige@2x.png) no-repeat 0 0; width: 20px; height: 14px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-mail-white { background: url(../images/sprite/ico-mail-white@2x.png) no-repeat 0 0; width: 20px; height: 14px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-mail { background: url(../images/sprite/ico-mail@2x.png) no-repeat 0 0; width: 20px; height: 14px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-pinterest-beige { background: url(../images/sprite/ico-pinterest-beige@2x.png) no-repeat 0 0; width: 14px; height: 18px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-pinterest-white { background: url(../images/sprite/ico-pinterest-white@2x.png) no-repeat 0 0; width: 14px; height: 18px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-pinterest { background: url(../images/sprite/ico-pinterest@2x.png) no-repeat 0 0; width: 14px; height: 18px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-quote { background: url(../images/sprite/ico-quote@2x.png) no-repeat 0 0; width: 30px; height: 25px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-twitter-beige { background: url(../images/sprite/ico-twitter-beige@2x.png) no-repeat 0 0; width: 16px; height: 13px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-twitter-white { background: url(../images/sprite/ico-twitter-white@2x.png) no-repeat 0 0; width: 12px; height: 9px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-twitter { background: url(../images/sprite/ico-twitter@2x.png) no-repeat 0 0; width: 16px; height: 13px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-vimeo-beige { background: url(../images/sprite/ico-vimeo-beige@2x.png) no-repeat 0 0; width: 14px; height: 12px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-vimeo-white { background: url(../images/sprite/ico-vimeo-white@2x.png) no-repeat 0 0; width: 14px; height: 12px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-vimeo { background: url(../images/sprite/ico-vimeo@2x.png) no-repeat 0 0; width: 14px; height: 12px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-youtube-beige { background: url(../images/sprite/ico-youtube-beige@2x.png) no-repeat 0 0; width: 15px; height: 15px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-youtube-white { background: url(../images/sprite/ico-youtube-white@2x.png) no-repeat 0 0; width: 15px; height: 15px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-youtube { background: url(../images/sprite/ico-youtube@2x.png) no-repeat 0 0; width: 15px; height: 15px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }}


#nyt {
  height: 72px;
  width: 230px;
  margin-bottom: 15px;

  @media (max-width: 400px) {
	max-width: 100%;
	width: 100%;
	height: auto;
  }
}