/* ------------------------------------------------------------ *\	
	Base
\* ------------------------------------------------------------ */

html {
	overflow-x: hidden; 
}

body {
	min-width: 320px; 
	background: $color-light; 
	font-family: $font-avenir; 
	font-size: $font-size-base; 
	line-height: $line-height-base; 
	color: $color-darkgrey; 
}

a {
	color: inherit; 
	text-decoration: none; 
}
a:hover {
	color: inherit; 
	text-decoration: underline; 
}
a:focus {
	color: inherit; 
	outline: 0; 
}

a[href^="tel"] {
	text-decoration: none; 
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0; 
	margin-bottom: calc(#{$line-height-base}em/2); 
	line-height: 1.1;
	font-weight: 900;
}

h1,
h2,
h3 {
	font-family: $font-tungsten;
	color: $color-beige;
	font-weight: 500;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

h1 {
	font-size: 70px;
	line-height: 1.08;
}

h2 {
	font-size: 50px;
	letter-spacing: 0.05em;
}

h3 {
	font-size: 30px;
}

h4 {
	font-size: 40px;
	line-height: 1.25;
}

h5 {
	font-size: 20px;
	line-height: 1.5;
}

h6 {
	font-size: 20px;
	font-weight: normal;
}

p,
ul,
ol,
dl,
table,
blockquote {
	margin-bottom: #{$line-height-base}em; 
}

p {
	margin-bottom: 27px; 
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
	margin-bottom: 0; 
}

/* ------------------------------------------------------------ *\	
	Media Queries
\* ------------------------------------------------------------ */

/* Mobile */

@include breakpoint-mobile {
	
	body {
		font-size: 14px;
		line-height: 1.43;
	}

	h1 {
		font-size: 38px;
	}

	h2 {
		font-size: 32px;
	}

	h3 {
		font-size: 28px;
	}

	h4 {
		font-size: 30px;
	}

	h5 {
		font-size: 18px;
		line-height: 1.3;
	}

	h6 {
		font-size: 18px;
	}
}

#blvr {
  margin-top: 25px;
  font-size: 14px;
}

.alert.alert-warning {
  text-align: center;
}

.copies-lb .gform_wrapper .top_label .gfield_label {
  display: none!important
}

.copies-lb .gform_wrapper .top_label li.gfield.gf_left_half,
.gform_wrapper .top_label li.gfield.gf_right_half {
  margin-top: 0!important;
  margin-bottom: 0!important
}

.form input[type=email],
.form input[type=password],
.form input[type=search],
.form input[type=tel],
.form input[type=text],
.form select,
.form textarea,
.form textarea .form input[type=email] {
  font-size: 16px!important;
  padding: 5px 10px!important
}

.copies-lb #field_3_4 {
  margin-right: 0
}

#gform_submit_button_3 {
  margin: 0;
  width: 95%;
  font-size: 12px
}

.copies-lb .gform_footer {
  padding-top: 0!important
}

.specialoffer {
  color: #9d855b;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;

  &:hover {
	color: #9d855b;

  }
}

.offer-content {
  .specialoffer {
	margin-top: 10px;
  }
}