/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	padding: 75px 0;
	background: $color-darkerblue;
	color: $color-light;
	text-align: center;

	h5 {
		margin-bottom: 30px;
		color: $color-beige;
		text-transform: uppercase;
		letter-spacing: 0.1em;
	}

	p {
		padding: 0 15px;

		a {
			color: $color-beige;
			letter-spacing: 0.02em;

			&[href^="tel"],
			&[href^="mailto"] {
				color: inherit;
				letter-spacing: 0;
			}

		  img {
			position: relative;
			top: -3px;
			margin-right: 10px;
		  }
		}

		small {
			display: block;
			font-size: 14px;
			line-height: 1.6;
		}

		& + .socials {
			margin-top: -4px;
		}
	}

	.list-logos {
		margin-bottom: 0px;
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.footer {

		p {
			padding: 0;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.footer {
		padding: 50px 0;

		p {

		}

	  .list-logos {
		margin-bottom: 20px;
	  }
	}
}

.tony-bar {
  padding: 85px 25px;
  background: #fff;
  text-align: center;

  @media (max-width: 767px) {
	padding: 45px 25px;
  }

  h4 {
	font-size: 26px;
	line-height: 32px;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 0;
	font-weight: 500;
	font-family: $font-tungsten;

	@media (max-width: 767px) {
	  font-size: 22px;
	  line-height: 26px;
	}

	a {
	  color: #9d855b;
	  text-decoration: none;
	}
  }

  img {
	margin-top: 23px;
  }
}