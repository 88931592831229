/* ------------------------------------------------------------ *\	
	List
\* ------------------------------------------------------------ */

[class^="list-"] {
	list-style: none outside none;
}

/* ------------------------------------------------------------ *\
	List Logos
\* ------------------------------------------------------------ */

.list-logos {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -30px;
	text-align: center;

	&:last-child {
		margin-bottom: -30px;
	}

	li {
		display: flex;
		flex: 0 0 170px;
		min-height: 50px;
		padding: 3px 0;
		margin: 0 15px 30px;
		border-radius: 5px;
		background: $color-light;

		a {
			display: flex;
			flex: 0 1 100%;
			justify-content: center;
			align-items: center;
			padding: 0 3px;
			transition: opacity .4s;

			&:hover {
				opacity: .7;
			}
		}
	}

  img {
	width: auto;
	max-height: 36px;
  }
}

.list-logos-secondary {
	margin: 0 -9px;

	&:last-child {
		margin-bottom: -13px;
	}

	li {
		flex: 0 0 172px;
		min-height: 52px;
		border: 1px solid #cccccc;
		margin: 0 9px 13px;
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.list-logos-secondary {

		li {
			flex: 0 0 164px
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.list-logos {
		margin: 0 -7px;

		&:last-child {
			margin-bottom: -13px;
		}

		li {
			flex: 0 0 150px;
			min-height: 45px;
			padding: 2px 0;
			margin: 0 7px 13px;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Logos Small
\* ------------------------------------------------------------ */

.list-logos-small {
	font-size: 0;

	li {
		position: relative;
		display: inline-block;
		vertical-align: bottom;

		& ~ li {
			padding-left: 40px;
			margin-left: 40px;

			&:before {
				position: absolute;
				bottom: -5px;
				left: 0;
				height: 60px;
				border-left: 1px solid #ab946f;
				content: '';
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.list-logos-small {

		li {
			
			& ~ li {
				padding-left: 12px;
				margin-left: 12px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	List Logos Xsmall
\* ------------------------------------------------------------ */

.list-logos-xsmall {
	margin-right: -79px;

	li {
		display: inline-block;
		margin-right: 79px;

		img {
			height: 42px;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.list-logos-xsmall {

		li {

			img {
				transform: scale(.6);
				transform-origin: 0 center;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	List Steps
\* ------------------------------------------------------------ */

.list-steps {
	counter-reset: steps;

	li {
		margin-bottom: 27px;
		font-size: 17px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	span {
		color: #484646;
		font-weight: 900;
		text-transform: uppercase;
		letter-spacing: 0.1em;

		&:after {
			counter-increment: steps;
			content: ' ' counter(steps) ":";
		}
	}

	a {
		color: #84724f;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.list-steps {

		li {
			margin-bottom: 20px;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Beige Dots
\* ------------------------------------------------------------ */

.list-beige-dots {
	column-count: 2;
	column-gap: 30px;
	list-style: none outside none;
	text-align: left;

	li {
		break-inside: avoid;
		position: relative;
		padding: 0 0px 28px 16px;

		&:before {
			position: absolute;
			top: 10px;
			left: 0;
			width: 4px;
			height: 4px;
			background: $color-beige;
			content: '';
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.list-beige-dots {
		column-count: 1;
	}
}

/* ------------------------------------------------------------ *\
	List Beige Checks
\* ------------------------------------------------------------ */

.list-beige-checks {
	column-count: 2;
	column-gap: 100px;
	text-align: left;
	list-style: none outside none;

	li {
		break-inside: avoid;
		position: relative;
		padding: 0 0 27px 36px;

		&:before {
			position: absolute;
			top: 2px;
			left: 2px;
			width: 22px;
			height: 17px;
			background: url(../images/ico-check@2x.png) no-repeat 0 0;
			-webkit-background-size: 100% 100%;
			background-size: 100% 100%;
			content: '';
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.list-beige-checks {
		column-gap: 50px;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.list-beige-checks {
		column-count: 1;

		li {

			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}

.app-list {
  padding-left: 10px;

  li {
	text-indent: -5px;
	list-style-type: none;

	&:before {
	  content: "- ";
	  text-indent: -5px;
	}
  }
}

#buy {
  .uk-buyer {

	@media (max-width: 767px) {
	  margin-top: 10px;
	}

	a {
	  margin: 0 15px;

	  @media (max-width: 767px) {
		display: block;
		margin: 10px;
	  }
	}
  }
}

.logo-title {
  display: block;
  text-align: center;
  font-size: 26px;
  line-height: 30px;
  text-transform: uppercase;
  color: #1b2a2d;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 5px;
}

.bar-body {
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
}

.audio-options {
  margin-top: 25px;

  .left {
	float: left;
	width: 38%;
	margin-right: 2%;
  }

  .right {
	float: left;
	width: 60%;
  }

  @media (max-width: 767px) {
	.left, .right {
	  width: 100%;
	  float: none;
	}
	.right {
	  margin-top: 25px;
	}
  }
}

.sample-container {
  max-width: 325px;
  margin: 25px auto 0 auto;
}

.audio-player {
  font-size: 12px;
  line-height: 24px;
  font-family: $font-avenir;
  text-transform: uppercase;
  color: #9d855b;
  font-weight: 700;
  border: 2px solid #9d855b;
  letter-spacing: 1px;

  span {

  }
}