.section-form {
  padding: 75px 15px;
  background: #fff;
}

.form-container {
  width: 100%;
  max-width: 870px;
  margin: 0 auto;
}

.gift-intro-content {
  max-width: 434px;
  margin-top: 56px;
}
.gift-intro-content h2 {
  font-size: 40px;
  line-height: 75px;
  margin: 0;
}
.gift-intro-content h1 {
  font-size: 65px;
  line-height: 80px;
  color: #ffffff;
}

#freebookcontent {
  margin-top: 0;
  padding: 104px 0 0 0;
}

#freebookcontent .section-foot {
  border-bottom: 0;
}

.form-container .gform_wrapper {
  margin: 0;
}

@media (max-width: 767px) {
  .section-form {
    padding: 50px 15px;
  }
  .gift-intro-content {
    margin-top: 0;
  }
  .gift-intro-content h1 {
    font-size: 36px;
    line-height: 40px;
    color: #9d855b;
  }
  .gift-intro-content h2 {
    font-size: 28px;
    line-height: 30px;
  }
  #freebookcontent {
    padding: 50px 0 0 0;
  }
}