/* ------------------------------------------------------------ *\	
	Container
\* ------------------------------------------------------------ */

/* Mobile */

@include breakpoint-mobile {
	.container {
		padding: 0 30px;
	}
}

/* Mobile */

@include breakpoint-mobile-portrait {
	.container {
		padding: 0 15px;
	}
}
