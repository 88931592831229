/* ------------------------------------------------------------ *\
	Offers
\* ------------------------------------------------------------ */

.offer {
	overflow: hidden;
	padding-left: 1px;
	margin-bottom: 97px;

	&:last-child {
		margin-bottom: 0;
	}

	.offer {

		&-image {
			overflow: hidden;
			float: left;
			width: 300px;
			border-radius: 5px;
			border: 1px solid $color-lightergrey;
			margin: 8px 70px 0 0;

			img {
				width: 100%;
			}
		}

		&-content {
			overflow: hidden;

			h5 {
				margin-bottom: 3px;
				font-size: 25px;
				letter-spacing: 0.01em;
			}

			h6 {
				margin-bottom: 33px;
				color: $color-grey;

				&:last-child {
					margin-bottom: 0;
				}
			}

			p {
				margin-bottom: 37px;
			}

			.btn-primary {
				min-width: 156px;
			}
		}
	}
}

.offers-small {

	.offer {
		margin-bottom: 46px;

		.offer {

			&-image {
				width: 140px;
				margin: 3px 37px 0 0;
			}

			&-content {
				h5 {
					margin-bottom: 2px;
					font-size: 22px;
					line-height: 1.2;
				}

				h6 {
					font-size: 16px;
					line-height: 1.65;
				}
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.offers-large {

		.offer {

			.offer {

				&-image {
					width: 260px;
					margin-right: 30px;
				}
			}
		}
	}

	.offers-small {

		.offer {

			.offer {

				&-image {
					margin-right: 15px;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.offer {
		margin-bottom: 40px;
	}

	.offers-large {

		.offer {

			.offer {

				&-image {
					float: none;
					width: auto;
					margin: 0 0 25px;
				}

				&-content {
					
					h5 {
						margin-bottom: 10px;
						font-size: 22px;
					}

					h6 {
						margin-bottom: 20px;
					}

					p {
						margin-bottom: 20px;
					}

					.btn-primary {
						min-width: 136px;
					}
				}
			}
		}
	}

	.offers-small {
		text-align: center;

		.offer {
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}

			.offer {

				&-image {
					float: none;
					display: block;
					margin: 0 auto 15px;
				}
			}
		}
	}
}
