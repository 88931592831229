/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider {

	.owl-item {
		backface-visibility: visible;
	}
}

/* ------------------------------------------------------------ *\
	Slider Testimonials
\* ------------------------------------------------------------ */

#mic {
	width: 36px;
	height: 59px;
	margin: 0 auto 42px;
}

.slider-testimonials {
	position: relative;
	border: 2px solid $color-beige;
	background: $color-light;

	.slide {
		display: table;
		width: 100%;
		height: 496px;

		&-content {
			display: table-cell;
			padding: 15px 30px;
			vertical-align: middle;
		}

		.testimonial {
			max-width: 770px;
			margin: 0 auto;
		}
	}

	.owl-prev,
	.owl-next {
		position: absolute;
		top: 50%;
		width: 50px;
		height: 50px;
		margin-top: -25px;
		border: 2px solid $color-beige;
		background: $color-beige !important;
		font-size: 0;
		transition: background .4s;
		text-indent: -999999px;

		&:hover {
			background: $color-light !important;

			&:before {
				border-color: $color-beige;
			}
		}

		&:before {
			position: absolute;
			top: 15px;
			left: 20px;
			@include chevron(16px, 2px, $color-light, 0 0 0 0, 45deg, 50% 50%, .4s);
		}
	}

	.owl-prev {
		left: -26px;
	}

	.owl-next {
		right: -27px;

		&:before {
			left: 13px;
			transform: rotate(-135deg);
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.slider-testimonials {
		.owl-prev {
			left: -26px;
		}

		.owl-next {
			right: -26px;
		}
	}
}

@media (max-width: $breakpoint-mobile) {
  .slider-testimonials {
	.owl-prev {
	  left: -32px;
	}
	.owl-next {
	  right: -32px;
	}
  }
}

@media (max-width: $breakpoint-mobile-portrait) {
  .slider-testimonials {
	.owl-prev {
	  left: -17px;
	}
	.owl-next {
	  right: -17px;
	}
  }
}