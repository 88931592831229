/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper {
	position: relative; 
	overflow: hidden; 
	min-height: 100%; 

	&-secondary {
		padding-top: 80px;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.wrapper {

		&-secondary {
			padding-top: 60px;
		}
	}
}
