/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */


.home, .gift {
  .header {
	position: absolute;
  }
  .header.fixed {
	position: fixed;
  }
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 80px;
	padding: 20px 15px;
	background: rgba(26,39,42,.4);
	transition: background .4s, box-shadow .4s;

	&.fixed {
		background: #f4f4f4;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

		.nav {

			li {

				&.active {

					a {
						color: $color-beige;
					}
				}
			}

			a {
				color: $color-darkgrey;

				&:hover {
					color: $color-beige;
				}
			}
		}
	}

	&-secondary {
		background: $color-light;

		.nav {

			a {
				color: $color-darkgrey;
			}
		}
	}
}

.header-inner {

	&:after {
		content: ''; 
		line-height: 0; 
		display: table; 
		clear: both; 
	}

	.logo {
		float: left;
	}
}

.header-content {
	float: right;

	.nav {
		display: inline-block;
		margin-top: 1px;
		vertical-align: middle;
	}
}

.header-actions {
	display: inline-block;
	padding-right: 2px;
	margin-left: 10px;
	vertical-align: middle;
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.header {
		padding-left: 0;
		padding-right: 0;
	}

	.header-actions {
		padding-right: 0;
		margin-left: 5px;

		.btn-primary {
			min-width: 140px;
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.header {

		&-secondary,
		&.fixed {
			
			.nav {

				a {
					color: $color-light;
				}
			}

			.nav-mobile {

				span {
					background: $color-beige;
				}
			}

			&.expanded {

				.nav-mobile {

					span {
						background: $color-light;
					}
				}
			}
		}

		&.expanded {
			background: #122226;

			.header-content {
				opacity: 1;
				visibility: visible;
				transform: scale(1) translateX(0);
			}
		}
	}

	.header-content {
		position: fixed;
		top: 80px;
		left: 0;
		float: none;
		overflow-y: auto;
		max-height: calc(100vh - 80px);
		width: 100%;
		padding: 20px 40px;
		background: #122226;
		text-align: right;
		opacity: 0;
		visibility: hidden;
		transform: scale(0) translateX(100%);
		transform-origin: 100% 0;
		transition: transform .4s, opacity .4s, visibility .4s;

		.nav {
			display: block;
			margin-top: 0;
		}
	}

	.header-actions {
		display: none;
		margin-left: 0;
	}
}

.nyt-bar {
  background: #19262a;
  color: #fff;
  display: block;
  width: 100%;
  padding: 28px 15px;
  text-align: center;

  @media (max-width: 1050px) {
	display: none;
  }

  .cp-logo {
	  width: 49px;
	  height: auto;
	  position: absolute;
	  top: 28px;
	  left: 20px;

	  > img {
		  display: block;
	  }
  }

  @media (max-width: 1280px) {

	.cp-logo {
		width: 43px;
	}

  }

  .award {
	display: inline-block;
	margin: 0 15px;

	.number {
	  display: block;
	  float: left;
	  font-family: Georgia, Times, "Times New Roman", serif;
	  font-style: italic;
	  font-size: 30px;
	  line-height: 34px;
	  vertical-align: top;
	  color: #ab946f;
	}

	> div {
	  float: left;
	  border-left: 1px solid #ab946f;
	  padding-left: 15px;
	  margin-left: 15px;
	  text-align: left;

	  .title {
		display: block;
		float: none;
		font-size: 30px;
		line-height: 33px;
		color: #ffffff;
		text-transform: uppercase;
		font-family: $font-tungsten;
		font-weight: 500;
	  }

	  .best {
		display: block;
		float: none;
		font-family: Georgia, Times, "Times New Roman", serif;
		font-style: italic;
		font-size: 14px;
		line-height: 24px;
		text-transform: uppercase;
		color: #ab946f;
		letter-spacing: 1px;
	  }
	}

	@media (max-width: 1280px) {
	  margin: 0 10px;

	  .number {
		font-size: 26px;
		line-height: 30px;
	  }

	  > div {
		padding-left: 10px;
		margin-left: 10px;

		.title {
		  font-size: 26px;
		  line-height: 30px;
		}

		.best {
		  font-size: 13px;
		  line-height: 20px;
		  letter-spacing: 0.5px;
		}
	  }

	}
  }
}

/* Mobile */

@include breakpoint-mobile {
	.header {
		height: 60px;
		padding: 19px 0;
	}

	.header-inner {

		.logo {
			width: 144px;
			height: 22px;
		}
	}

	.header-content {
		top: 60px;
		max-height: calc(100vh - 60px);

		.nav {
			min-height: 300px;
		}
	}
}
