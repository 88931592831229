/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Clear  */
.clear:after {
	content: ''; 
	line-height: 0; 
	display: table; 
	clear: both; 
}


/*  Notext  */
.notext {
	font-size: 0; 
	line-height: 0; 
	text-indent: -4000px; 
}


/*  Links  */
.link {
	display: inline-block;
	padding: 2px 0;
	font-size: 13px;
	color: inherit;
	font-weight: 900;
	text-transform: uppercase;
	vertical-align: middle;
	letter-spacing: 0.1em;
	transition: color .4s;

	&:hover {
		color: $color-beige;
		text-decoration: none;
	}

	&:focus {
		text-decoration: none;
	}

	&-beige {
		color: $color-beige;

		&:hover {
			color: $color-darkgrey;
		}
	}

	&-secondary {
		font-size: 13px;
		font-weight: 900;
		text-transform: uppercase;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.link {
		font-size: 11px;
	}
}


/*  Grid  */
.centered {
	float: none; 
	margin: 0 auto; 
}

