/* ------------------------------------------------------------ *\
	Stats
\* ------------------------------------------------------------ */

.stats {

	.row {
		display: flex;
		flex-wrap: wrap;

		&:before,
		&:after {
			display: none;
		}
	}

	[class^="col-"] {
		flex: 0 0 33.33%;
		max-width: 33.33%;
		border-right: 1px solid #dfdfdf;

		&:nth-child(3n) {
			border-right: none;
		}
	}
}

.stat {
	padding: 43px 30px 77px 90px;

	> span {
		display: block;
		margin-bottom: 2px;
		font-family: $font-tungsten;
		font-size: 100px;
		color: $color-beige;
		letter-spacing: 0.1em;
	}

	h3 {
		margin-bottom: 14px;
		font-size: 35px;
		color: inherit;
		text-transform: none;
	}

	p {
		font-size: 22px;
		line-height: 1.1;
		color: $color-grey;
		font-style: italic;
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.stat {
		padding-left: 30px;
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.stat {
		padding-left: 0;
		padding-right: 0;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.stats {
		text-align: center;

		.row {
			display: block;
		}

		[class^="col-"] {
			max-width: none;
			border-right: none;
		}
	}

	.stat {
		max-width: 240px;
		padding: 20px 0 30px;
		margin: 0 auto;

		> span {
			line-height: 1;
		}

		p {
			font-size: 20px;
		}
	}
}
