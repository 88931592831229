/* ------------------------------------------------------------ *\
	Bar
\* ------------------------------------------------------------ */

.bar {
	padding: 40px 0;
	text-align: center;

	&-grey {
		background: $color-lightgrey;
	}

	&-darkblue {
		background: $color-darkerblue;
		color: $color-light;
	}

  .uk-buyer {
	img {
	  position: relative;
	  top: -3px;
	  margin-right: 10px;
	}

	a {
	  color: #9d855b;
	  font-weight: bold;
	}
  }
}

/* Mobile */

@include breakpoint-mobile {
	.bar {
		padding: 37px 0 34px;
	}
}

.error404 .header {
  box-shadow: 0 1px 5px rgba(0,0,0,.2);
}
