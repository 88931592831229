/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {

	ul {
		font-size: 0;
		list-style: none outside none;
	}

	li {
		display: inline-block;
		margin: 0 18px;
		vertical-align: top;

		&.active {

			a {
				color: $color-beige;
			}
		}
	}

	a {
		font-size: 13px;
		color: #fff;
		font-weight: 900;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		transition: color .4s;

		&:hover {
			color: $color-beige;
			text-decoration: none;
		}

		&:focus {
			text-decoration: none;
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.nav {

		li {
			margin: 0 4px;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.nav {

		li {
			display: block;
		}

		a {
			display: block;
			padding: 12px 0 11px;
			font-size: 16px;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.nav {

		
	}
}

/* ------------------------------------------------------------ *\
	Nav Mobile
\* ------------------------------------------------------------ */

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	
	.nav-mobile {
		position: absolute; 
		top: 22px; 
		right: 15px; 
		width: 30px; 
		height: 26px; 
		z-index: 99; 
		margin-top: 0;

		span {
			display: block; 
			width: 100%; 
			height: 3px; 
			border-radius: 3px;
			margin: 5px 0; 
			background: $color-light;

			&:nth-child(1) {
				@include transition(all 0.5s ease 0s); 
			}
			&:nth-child(2) {
				@include transition(all 0.4s ease 0s); 
			}
			&:nth-child(3) {
				@include transition(all 0.3s ease 0s); 
			}
		}

		&:hover {

			span {
				transform: rotate3d(0, 1, 0.1, 180deg);
			}
		}

		&.active {

			span {
				opacity: 0; 
				position: absolute; 
				top: 25%; 
				left: 0; 
				@include transform-origin(50% 50%);

				&:first-child {
					opacity: 1;
					transform: rotate3d(0, 0, 1, 45deg);
				}

				&:last-child {
					width: 100%; 
					opacity: 1;
				    transform: rotate3d(0, 0, 1, -45deg);
				}
			}
		}
	}	
}

/* Mobile */

@include breakpoint-mobile {
	.nav-mobile {
		top: 17px; 
		width: 22px; 
		height: 26px; 

		span {
			height: 2px; 
			margin: 5px 0; 
		}
	}
}
