/* ------------------------------------------------------------ *\
	Post Items
\* ------------------------------------------------------------ */

.post-item {
	margin-bottom: 30px;

	a {
		position: relative;
		display: block;

		&:hover {

			.post-item-image {

				img {
					transform: scale(1.1);
				}
			}
		}
	}

	.post-item {

		&-image {
			overflow: hidden;

			img {
				width: 100%;
				transition: transform .4s;
			}
		}

		&-content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			color: $color-light;
			text-transform: uppercase;

			h6,
			span {
				position: absolute;
				left: 0;
				width: 100%;
				padding: 0 15px;
			}

			span {
				top: 16px;
				font-size: 11px;
				font-weight: bold;
				letter-spacing: 0.03em;
			}

			h6 {
				bottom: 62px;
				min-height: 46px;
				font-size: 21px;
				letter-spacing: -0.01em;
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.post-item {

		.post-item {

			&-content {

				h6 {
					bottom: 20px;
					min-height: 0;
					font-size: 18px;
				}
			}
		}		
	}
}
