/* ------------------------------------------------------------ *\
	Awards
\* ------------------------------------------------------------ */

.awards {

	.row {
		display: flex;
		flex-wrap: wrap;

		&:before,
		&:after {
			display: none;
		}

		[class^="col-"] {
			padding: 98px 0 97px;
			border-right: 1px solid #bebebe;

			&:nth-child(4n) {
				border-right-color: transparent;
			}

			&:nth-child(4) ~ [class^="col-"] {
				margin-top: -66px;
			}
		}
	}
}

.award {
	text-align: center;

	.award {

		&-image {
			display: table;
			width: 100%;
			height: 54px;

			a {
				display: table-cell;
				vertical-align: middle;
			}

			img {
				max-height: 48px;
    			max-width: 130px;
    			width: auto;
    			height: auto;
			}
		}

		&-content {
			display: table;
			width: 100%;

			p {
				display: block;
				padding: 5px;
				font-size: 18px;
				line-height: 1.4;
				vertical-align: middle;
			  	margin: 10px 0;
			}

		  	h6 {
			  margin-bottom: 10px;
			}
		}

		&-actions {
			padding-top: 0px;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	
	.awards {

		.row {

			[class^="col-"] {
				padding: 66px 0;

				&:nth-child(even) {
					border-right: none;
				}

				&:nth-child(2) ~ [class^="col-"] {
					margin-top: -66px;
				}
			}
		}
	}
}
