.report-container {
  max-width: 1200px;
  padding: 50px 15px 0 15px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding-top: 0px;
  }
}

.columns-list {
  .row + .row {
    margin-top: 65px;
    @media (max-width: 767px) {
      margin-top: 35px;
    }
  }
  .row {
    > div {
      @media (max-width: 767px) {
        height: auto !important;
      }
    }
  }
}

.intro-form-box {
  padding: 25px 0 65px;

  @media (max-width: 767px) {
    padding-bottom: 35px;
  }

  h1 {
    font-size: 90px;
    line-height: 100px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  p {
    font-size: 18px;
    color: #888888;
  }
}

.subscribe-styles {
  width: 410px;
  margin: 0 auto;

  .gform_wrapper .top_label .gfield_label {
    display: none;
  }

  .gform_wrapper .gform_body {
    width: 270px;
    float: left;
  }

  .gform_wrapper .gform_footer {
    margin: 0;
    padding: 0;
    clear: none;
    float: right;
    width: auto;
  }
  .gform_wrapper ul.gform_fields li.gfield {
    padding: 0;
    margin: 0;
  }

  .gform_wrapper .top_label div.ginput_container {
    margin-top: 0;
  }

  .ginput_container_email {
    &:before {
      content: '';
      position: absolute;
      display: block;
      background-image: url('/wp-content/uploads/golden-arrow.png');
      background-size: 45px 32px;
      height: 32px;
      width: 45px;
      margin-left: -60px;
      margin-top: -8px;
    }

    input {
      text-transform: uppercase;
      border: 1px solid #ccc;
      background: #fff;
      padding-left: 20px !important;
      height: 40px;
      font-size: 12px !important;
      color: #888888 !important;
      font-weight: bold;
      letter-spacing: 1px !important;
    }
  }

  .gform_footer {
    .gform_button {
      text-transform: uppercase;
      font-size: 13px !important;
      color: #fff !important;
      font-weight: bold;
      background: #9d855b;
      border: 1px solid #9d855b;
      height: 40px;
      width: 132px !important;
      letter-spacing: 1px;
      margin: 0 !important;

      &:hover {
        color: #9d855b !important;
        background: transparent;
        transition: all 0.3s ease;
      }
    }
  }
  .gform_wrapper .gform_ajax_spinner {
    position: absolute;
  }
  .validation_error {
    display: none !important;
  }
  .gform_wrapper .field_description_below .gfield_description {
    padding-top: 3px !important;
    font-weight: 400 !important;
    color: #790000 !important;
  }
  .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container, .gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label{
    margin-top: 0 !important;
  }
  .gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    background: none;
    border: 0;
  }
}

.pos-rel {
  position: relative;
}

.center-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 767px) {
    position: static;
    transform: none;
    top: auto;
    padding-bottom: 25px;
  }
}

.form-box {
  padding: 90px 0;

  h1 {
    font-weight: 600;
    font-size: 90px;
    line-height: 100px;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    color: #888888;
  }

  &:last-child {
    padding-top: 0;
  }
}

#book-img {
  display: block;
  margin: auto;
}

@media (max-width: 767px) {
  .intro-form-box h1, .form-box h1 {
    font-size: 60px;
    line-height: 70px;
  }
  .form-box {
    padding: 45px 0;

    p {
      font-size: 14px;
      line-height: 1.43;
    }
  }
}

@media (max-width: 560px) {
  .subscribe-styles {
    width: 100%;
    .ginput_container_email:before {
      display: none;
    }
    .gform_wrapper .gform_body {
      width: 100%;
    }
    .gform_wrapper .gform_footer {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 15px;

      .gform_button {
        width: 100% !important;
      }
    }
  }
}

#thankyou {
  max-width: 605px;
  padding: 63px 50px 65px;
  text-align: left;

  .logo-popup {
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  h3 {
    max-width: 350px;
    margin: 0 auto 30px auto;
  }

  p {
    font-size: 16px;
    line-height: 26px;

    a {
      color: #9d855b;
    }
  }

  .btn-primary {
    display: inline-block;
    color: #fff;

    &:hover {
      text-decoration: none;
      color: #9d855b;
    }
  }
}

.after-form {
  text-align: center;
  margin-top: 35px;

  @media (max-width: 767px) {
    margin-top: 5px;
    margin-bottom: 10px;

    p {
      font-size: 14px;
    }
  }
}

#owner-reports {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}

.owner-reports-list {
  margin-top: 48px;

  .dark p {
    color: #d4d4d4;
  }

  > div {
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 50px;
    }

    img {
      margin-bottom: 30px;
    }

    p {
      font-size: 18px;
      line-height: 26px;
      color: #282828;
      text-transform: uppercase;
      padding: 0 15px;
      font-weight: bold;
    }
  }
}