body {
  .gf-styles {

    #label_13_4_1 {
      font-weight: 400 !important;
      margin-top: 5px;
    }

    .no-label {
      .gfield_label {
        display: none !important;
      }
    }

    input[type="checkbox"] {
      margin-right: 7px;
    }

    .gform_wrapper input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), .gform_wrapper select {
      text-transform: uppercase;
      border: 1px solid #ccc;
      background: #fff;
      padding-left: 20px !important;
      height: 40px;
      font-size: 12px !important;
      color: #888 !important;
      font-weight: 700;
      letter-spacing: 1px !important;
    }

    .gform_wrapper .top_label div.ginput_container {
      margin-top: 0 !important;
    }
    .gform_wrapper .top_label .gfield_label {
      margin: 0 !important;
      font-size: 13px !important;
      color: #888 !important;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .gform_fields {
      .gfield:first-child {
        margin-top: 0 !important;
      }
    }

    .gform_footer .gform_button {
      min-width: 154px;
      margin-right: 14px;
      height: auto;
      padding: 10px 15px;
      border-radius: 0;
      border: 2px solid #9d855b;
      background: #9d855b;
      font-size: 13px !important;
      line-height: 1.3;
      color: #fff;
      font-weight: 900;
      letter-spacing: .1em;
      text-transform: uppercase;
      display: inline-block;
      text-align: center;
      white-space: normal;
      vertical-align: middle;
      -webkit-appearance: none;
      transition: border .4s, background .4s, color .4s, opacity .4s, transform .4s;
      touch-action: manipulation;
      cursor: pointer;
      user-select: none;

      &:hover, &:focus {
        color: #9d855b;
        background: #fff;
      }
    }

    .gform_confirmation_message {
      text-align: center;
      margin-top: 35px;
      font-weight: bold;
    }
  }
}
