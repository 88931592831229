/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro {
	position: relative;
	overflow: hidden;

	.intro-image {
		min-height: 800px;
		height: calc(100vh - 120px);
	}

	.intro-content {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		width: 100%;

		&-image {
			margin-left: -31px;
		  	text-align: right;
		}

		&-inner {
			padding-top: 10px;
			color: $color-light;

			> span {
				display: inline-block;
				padding: 5px 18px 6px;
				margin-bottom: 27px;
				background: $color-light;
				font-family: $font-tungsten;
				font-size: 25px;
				color: $color-darkgrey;
				font-weight: 500;
				letter-spacing: 0.02em;
				text-transform: uppercase;
			}

			h1 {
				margin-bottom: 25px;
				color: inherit;
				color: #ab946f;

				span {
					color: #fff;
				}
			}

			.btn-primary {
				min-width: 154px;
				margin-right: 14px;
			}

			.btn-primary,
			.link {
				margin-bottom: 10px;
			}
		}
	}

	.intro-inner {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		color: $color-light;
		text-align: center;
		transform: translateY(-50%);

		h1 {
			color: inherit;
			letter-spacing: 0.05em;
		}
	}

	.owl-nav {
		display: none;
	}

	.owl-dots {
		position: absolute;
		bottom: 28px;
		left: 0;
		right: 0;
		text-align: center;

		@media (max-width: 767px) {
			bottom: 25px;
		}

		.owl-dot {
			height: 12px;
			width: 12px;
			background: #fff;
			border-radius: 36px;
			margin: 0 3px;
		}

		.owl-dot.active {
			background: #929a9c;
		}
	}
}

.intro-secondary {

	.intro-image {
		min-height: 600px;
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.intro {

		.intro-content {

			&-image {
				margin-left: -15px;
			}

			&-inner {
				padding-top: 0px;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.intro {
		
		.intro-image {
			min-height: 300px;
		}

		.intro-content {
			position: relative;
			top: 0;
			padding: 97px 15px 36px;
			margin-top: 0;
			transform: translateY(0);

			&-image {
				max-width: 172px;
				margin-left: 0;
				margin-bottom: 17px;
			}

			&-inner {
				
				> span {
					padding: 0;
					margin-bottom: 15px;
					background: none;
					font-size: 23px;
					color: $color-beige;
				}

				h1 {
					line-height: 1.07;
				}

				.btn-primary {
					min-width: 136px;
					margin-bottom: 25px;
				}

				.link {
					display: block;
					color: $color-grey;
				}
			}
		}

		.intro-inner {
			margin-top: 15px;
		}
	}

	.intro-primary {

		.intro-image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			min-height: 700px;
		}
	}
}

.slide {
	position: relative;
	min-height: 800px;
}

.podcast-intro {
	text-align: center;
}

.podcast-intro p {
	color: #fff;
	font-size: 19px;
	font-weight: 500;
}

.podcast-intro h1 span:nth-child(1) {
	display: block;
	color: #ab946f;
	font-size: 24px;
	font-family:'Avenir Next W01';
	font-weight: 700;
}

.podcast-intro h1 span:nth-child(2) {
	display: block;
	color: #fff;
	font-size: 100px;
	font-family: 'Tungsten';
	font-weight: 600;
}

.podcast-intro h1 span:nth-child(3) {
	display: block;
	color: #ab946f;
	font-size: 54px;
	font-family:'Avenir Next W01';
	font-weight: 700;
}

.investments-intro {
	text-align: center;
}

.investments-intro h1 {
	font-size: 70px;
	line-height: 75px;
	color: #fff;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
}

.investments-intro .footnote {
	font-size: 13px;
	line-height: 20px;
	max-width: 960px;
	padding: 0 15px;
	color: #717479;
	margin: 42px auto 0;
}

#bookimg {
	float: right;
	width: auto;
  margin-right: 20px;
}

@media (max-width: 767px) {
	#bookimg {
		float: none;
	}
	.investments-intro h1 {
		font-size: 50px;
		line-height: 1.1;
	}

	.podcast-intro h1 {
		line-height: 1.2;
	}

	.podcast-intro h1 span:nth-child(2) {
		font-size: 60px;
	}

	.podcast-intro h1 span:nth-child(3) {
		font-size: 24px;
	}
}
