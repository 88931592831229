.intro-gso {
  text-align: center;
}

.intro-gso.intro .intro-image {
  min-height: 700px;
  height: auto;
}

.intro-gso h1 {
  color: #fff;
  max-width: 512px;
  margin: 0 auto 34px;
}

.intro-gso .btn-video {
  display: inline-block;
}

.intro-gso .video-container {
  max-width: 600px;
  width: 100%;
  margin: 0 auto 56px;
  display: block;
}

.gso-content {
  background-color: #19262a;
  color: #fff;
  padding: 50px 0 92px;
}

.gso-content .container {
  max-width: 1030px;
  width: 100%;
  padding: 0 30px;
  text-align: center;
}

.checkmark {
  width: 54px;
  height: 53px;
  margin-bottom: 26px;
}

.icon-columns {
  tex-align: center;
  margin: 44px 0 64px;
}

p.footnote {
  font-size: 13px;
  line-height: 20px;
  color: #5e6769;
}

@media (min-width: 768px) {
  .btn-gso {
    margin: 36px auto 38px;
  }

  .icon-columns .col-sm-4 {
    padding: 0 42px;
  }
}

@media (max-width: 767px) {
  .icon-columns .col-sm-4  + .col-sm-4 {
    margin-top: 25px;
  }

  .icon-columns {
    margin: 36px 0;
  }
}

.responsive-video-container {
  position: relative;

  video {
    max-width: 100%;
  }
}

.video-after {
  display: none;
}