/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	padding: 100px 0;
}

/* Mobile */

@include breakpoint-mobile {
	.section {
		padding: 50px 0;
	}
}

/* ------------------------------------------------------------ *\
	Section Lightgrey
\* ------------------------------------------------------------ */

.section-lightgrey {
	background: #f5f5f5;
}

/* ------------------------------------------------------------ *\
	Section Offers
\* ------------------------------------------------------------ */

.section-offers {
	padding: 87px 0;

	h2 {
		padding-bottom: 4px;
		border-bottom: 1px solid $color-lightergrey;
	}

	.offers {
		padding-top: 3px;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-offers {
		padding: 50px 0;
	}
}

/* ------------------------------------------------------------ *\
	Section Testimonials
\* ------------------------------------------------------------ */

.section-testimonials {
	position: relative;
	z-index: 2;
	overflow: hidden;
	padding: 50px 0 0;

	.section-body {
		margin: 0 -30px;

		h2 {
			margin-bottom: 31px;
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.section-testimonials {

		.section-body {
			margin: 0;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-testimonials {
		padding-top: 50px;

		.section-body {

			h2 {

				br {
					display: none;
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Primary
\* ------------------------------------------------------------ */

.section-primary {
	position: relative;
	overflow: hidden;
	background: #0F2125;
	color: $color-light;

	.section-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.container {
		position: relative;
		z-index: 2;
	}
}

/* ------------------------------------------------------------ *\
	Section History
\* ------------------------------------------------------------ */

.section-history {
	padding: 335px 0 104px;
	margin-top: -249px;
	text-align: center;

	.section-bg {
		height: auto;

		img {
			display: block;
			width: 100%;
			opacity: 0;
		}
	}

	.section-head {
		margin-bottom: 33px;

		span {
			display: block;
			font-family: $font-tungsten;
			font-size: 31px;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 0.04em;
		}
	}

	.section-body {
		text-align: left;

		p {

			& + ul {
				padding-top: 16px;
			}
		}

		ul {
			
			&:not([class]) {
				column-count: 2;
				column-gap: 30px;
				list-style: none outside none;

				li {
					break-inside: avoid;
					position: relative;
					padding: 0 5px 28px 16px;

					&:before {
						position: absolute;
						top: 10px;
						left: 0;
						width: 4px;
						height: 4px;
						background: $color-beige;
						content: '';
					}
				}
			}
		}
	}

	.section-foot {
		padding: 71px 0 75px;
		border-top: 1px solid #323f43;
		border-bottom: 1px solid #323f43;
		margin-top: 52px;

		.list-logos-small {
			padding-right: 12px;
			margin-bottom: 54px;
		}

		p {
			max-width: 770px;
			margin: 0 auto 14px;
			font-family: $font-proxima-nova;
			font-size: 28px;
			line-height: 1.38;
			font-weight: bold;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-history {
		padding: 300px 0 50px;

		.section-head {

			span {
				font-size: 26px;
			}
		}

		.section-body {

			ul:not([class]) {
				padding-top: 0;
				column-count: 1;

				li {
					padding-right: 0;
					padding-bottom: 15px;
				}
			}
		}

		.section-foot {
			padding: 40px 0;
			margin-top: 40px;

			p {
				font-size: 22px;
			}

			.list-logos-small {
				padding-right: 0;
				margin-bottom: 30px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Articles
\* ------------------------------------------------------------ */

.section-articles {
	padding: 103px 0 34px;
}

/* Mobile */

@include breakpoint-mobile {
	.section-articles {
		padding: 50px 0 25px;
	}
}

/* ------------------------------------------------------------ *\
	Section Videos
\* ------------------------------------------------------------ */

.section-videos {
	padding: 74px 0 88px;

	.section-body {
		padding: 0 71px;
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.section-videos {

		.section-body {
			padding: 0;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-videos {
		padding: 50px 0 40px;
	}
}

/* ------------------------------------------------------------ *\
	Section About
\* ------------------------------------------------------------ */

.section-about {
	position: relative;
	z-index: 2;
	

	.section-image {
		margin: -191px 0 77px;

		a {
			position: relative;
			display: block;

			&:hover {
				
				&:before {
					transform: scale(1.1);
				}
			}

			&:before {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 80px;
				height: 80px;
				margin: -40px 0 0 -40px;
				background: url(../images/ico-play@2x.png) no-repeat 0 0;
				-webkit-background-size: 100% 100%;
				background-size: 100% 100%;
				content: '';
				transition: transform .4s;
			}
		}

		img {
			width: 100%;
		}
	}

	.section-content {
		max-width: 760px;
		margin: 0 auto;

		img {
			margin-bottom: 34px;
		}

		h2 {
			margin-bottom: 29px;
		}

		h6 {
			margin-bottom: 41px;
			line-height: 1.5;
		}
	}

}

/* Mobile */

@include breakpoint-mobile {
	.section-about {
		
		.section-image {
			margin: -80px 0 40px;

			a {

				&:before {
					width: 60px;
					height: 60px;
					margin: -30px 0 0 -30px;
				}
			}
		}

		.section-content {

			img {
				margin-bottom: 30px;
			}

			h2 {
				margin-bottom: 20px;
			}

			h6 {
				margin-bottom: 30px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Report
\* ------------------------------------------------------------ */

.section-report {
	padding: 77px 0;

	.section-body {
		display: flex;
		align-items: center;
		padding-left: 69px;
	}

	.section-image {
		flex: 0 0 51.7%;
		margin-right: 32px;

		img {
			width: 100%;
		}
	}

	.section-content {
		flex: 0 1 100%;
		padding: 15px 0 40px;

		h2 {
			margin-bottom: 29px;
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.section-report {

		.section-body {
			padding-left: 0;
		}

		.section-image {
			margin-right: 20px;
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-report {

		.section-content {

			h2 {

				br {
					display: none;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-report {
		padding: 50px 0;

		.section-body {
			display: block;
		}

		.section-image {
			margin: 0 0 30px;
		}

		.section-content {
			padding: 0;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Provide
\* ------------------------------------------------------------ */

.section-provide {
	padding: 90px 0 66px;

	.section-body {

		h2 {
			margin-bottom: 26px;
		}

		p {
			margin-bottom: 47px;
		}

		ul {
			column-count: 2;
			column-gap: 100px;
			padding: 0 33px;
			text-align: left;
			list-style: none outside none;

			li {
				break-inside: avoid;
				position: relative;
				padding: 0 0 27px 36px;

				&:before {
					position: absolute;
					top: 2px;
					left: 2px;
					width: 22px;
					height: 17px;
					background: url(../images/ico-check@2x.png) no-repeat 0 0;
					-webkit-background-size: 100% 100%;
					background-size: 100% 100%;
					content: '';
				}
			}
		}

		img {
			margin-bottom: 78px;
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.section-provide {

		.section-body {

			ul {
				padding: 0 20px;
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-provide {

		.section-body {

			ul {
				padding: 0;
				column-gap: 50px;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-provide {
		padding: 50px 0;

		h2 {

			br {
				display: none;
			}
		}

		.section-body {

			p {
				margin-bottom: 30px;
			}

			img {
				margin-bottom: 30px;
			}

			ul {
				column-count: 1;

				li {

					&:last-child {
						padding-bottom: 0;
					}
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Podcast
\* ------------------------------------------------------------ */

.section-podcast {
	position: relative;
	padding: 90px 0;

	h2 {
		margin-bottom: 67px;
		text-align: center;
	}

	p {

		& + .player {
			margin-top: -18px;
		}
	}

	h3 {
		margin-bottom: 28px;
	}

	ol {
		list-style: none outside none;
	}

	.player {
		padding: 5px 0;
		margin: 0 -83px 61px;

		&:last-child {
			margin-bottom: 0;
		}

		img {
			width: 100%;
		}
	}

	.socials-tertiary {
		position: absolute;
		top: -104px;
		left: 50%;
	  	z-index: 5;
		margin-left: -769px;
	}

	.socials-tertiary.sticky {
	  position: fixed;
	  top: 136px;
	}
}

/* Large Desktop */

@include breakpoint-large-desktop {
	.section-podcast {

		.socials-tertiary {
			left: 15px;
			margin-left: 0;
		}	
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.section-podcast {
		padding-top: 100px;

		.player {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-podcast {
		padding-top: 50px;

		.socials-tertiary {
			position: relative;
			top: 0;
			left: 0;
			margin-left: 0;
			margin-bottom: 50px;
		}
		.socials-tertiary.sticky {
		  position: relative;
		  top: 0;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-podcast {
		padding: 50px 0;

		h2 {
			margin-bottom: 30px;

			br {
				display: none;
			}
		}

		h3 {
			margin-bottom: 20px;
		}

		p {

			& + .player {
				margin-top: 0;
			}
		}

		.player {
			margin-bottom: 25px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Related
\* ------------------------------------------------------------ */

.section-related {
	padding: 32px 0 109px;

	h2 {
		margin-bottom: 67px;
		text-align: center;
		letter-spacing: 0.02em;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-related {
		padding: 50px 0;

		h2 {
			margin-bottom: 30px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section App
\* ------------------------------------------------------------ */

.section-app {
	padding: 132px 0 158px;

	.section-image {
		padding-left: 17px;
		text-align: center;
	}

	.section-content {
		padding: 27px 70px 0 0;

		h2 {
			margin-bottom: 28px;
		}

		p {

			& + .btn-app {
				margin-top: 10px;
			}
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.section-app {

		.section-image {
			padding-left: 0;
		}

		.section-content {
			padding: 0;
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-app {
		padding: 80px 0;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-app {
		padding: 30px 0 50px;

		.section-image {
			margin-bottom: 40px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Blog
\* ------------------------------------------------------------ */

.section-blog {
	position: relative;
	padding: 88px 0 86px;

	.socials-tertiary {
		position: fixed;
		left: 30px;
		top: 232px;
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.section-blog {

		.socials-tertiary {
			left: 15px;
		}

		.article-secondary {

			.article {

				&-image {
					padding: 0 60px;
				}
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-blog {
		padding-top: 50px;

		.socials-tertiary {
			position: relative;
			top: 0;
			left: 0;
			margin-bottom: 50px;
		}

		.article-secondary {

			.article {

				&-image {
					padding: 0;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-blog {
		padding: 30px 0;

		.socials-tertiary {
			position: relative;
			top: 0;
			left: 0;
			margin-bottom: 30px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Personal
\* ------------------------------------------------------------ */

.section-personal {
	padding: 119px 0 52px;

	.section-content {
		padding: 0 20px 0 35px;

		> p {
			font-size: 13px;
			line-height: 1.3;
			color: #777;

			strong {
				font-weight: 900;
			}
		}

		.list-logos-xsmall {
			margin-bottom: 50px;
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-personal {
		padding-top: 80px;

		.section-content {
			padding: 0;

			.list-logos-xsmall {
				margin-bottom: 30px;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-personal {
		padding: 40px 0;
	}
}

/* ------------------------------------------------------------ *\
	Section Plan
\* ------------------------------------------------------------ */

.section-plan {
	padding: 74px 0 103px;

	.section-bg {
		background-position: right 50%;
	}

	.section-content {
		padding-left: 34px;
		color: #1c4360;

		> img {
			max-width: 165px;
			margin-bottom: 81px;
		}

		h2 {
			margin-bottom: 36px;
			color: inherit;
			text-transform: none;
			letter-spacing: 0.05em;
		}

		p {
			font-size: 18px;
			line-height: 1.55;

			& + .btn-primary {
				margin-top: 31px;
			}
		}
	}

  @media (max-width: 991px) {
	&:before {
	  content: '';
	  background: #000;
	  opacity: 0.4;
	}
  }
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-plan {
		padding: 50px 0;

		.section-bg {
		  	background-image: url('/wp-content/uploads/plan-bg1dark.jpg') !important;
			background-position: 100% 100%;
		}

		.section-content {
			padding-left: 0;

			> img {
				margin-bottom: 30px;
			}

			h2 {
				margin-bottom: 30px;
			}

			p {
				font-size: 16px;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-plan {

		.section-bg {
			background-position: 85% 100%;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Awards
\* ------------------------------------------------------------ */

.section-awards {
	position: relative;

	.section-actions {
		position: absolute;
		bottom: 33px;
		left: 0;
		width: 100%;
		text-align: center;
	}
}

.callout-banner {
  padding: 25px 15px 20px;
  background-color: #efecea;
  text-align: center;
}

.callout-banner p {
  display: inline-block;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  color: #414042;
  padding: 0;
  margin: 0;
}
.callout-banner .btn {
  margin-top: -5px;
  margin-left: 45px;
  display: inline-block;
}

@media (max-width: 782px) {
  .callout-banner .btn {
	margin-left: 0;
	margin-top: 10px;
	display: block;
  }
}

/* ------------------------------------------------------------ *\
	Section Video
\* ------------------------------------------------------------ */

.section-video {
	position: relative;

	.section-inner {
		position: relative;
		overflow: hidden;
		padding-top: 43.8%;

		video {
			position: absolute;
		    top: 50%;
		    left: 50%;
		    min-width: 100%;
		    min-height: 100%;
		    max-width: none;
		    width: auto;
		    height: auto;
		    transform: translate(-50%, -50%);
		}
	}

	.section-content {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		color: $color-light;
		text-align: center;
		transform: translateY(-50%);

		h2 {
			margin-bottom: 60px;
			font-size: 60px;
			color: inherit;
			text-transform: none;
			letter-spacing: 0.1em;
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-video {

		.section-inner {
			padding-top: 60%;
		}

		.section-content {

			h2 {
				margin-bottom: 40px;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-video {

		.section-inner {
			min-height: 500px;
			padding-top: 0;
		}

		.section-content {

			h2 {
				font-size: 32px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Author
\* ------------------------------------------------------------ */

.section-author {
	position: relative;
	min-height: 781px;
	padding: 133px 0 64px;
	background: #efeeea;
	color: #221f20;

	h3 {
		margin-bottom: 43px;
		font-size: 40px;
		color: inherit;
		text-transform: none;
		letter-spacing: 0.1em;
	}

	h5 {
		margin-bottom: 38px;
		font-size: 22px;
		line-height: 1.3;
	}

	[class^="col-"] {
		position: static;
	}

	.section-image {
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -740px;
	}

	.section-content {
		position: relative;
		z-index: 2;
		margin-left: -20px;
	}

	.section-content-image {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 5px 80px 0 0;

		.btn-primary {
			margin-top: 20px;
		}

		img {
			flex: 0 0 auto;
			margin-left: 10px;
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.section-author {

		.section-content-image {
			padding-right: 0;
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-author {
		padding: 50px 0;

		.section-image {
			position: static;
			max-width: 60%;
			margin: 0 auto 30px;
		}

		.section-content {
			margin-left: 0;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-author {

		h3 {
			margin-bottom: 25px;
		}

		h5 {
			margin-bottom: 25px;
			font-size: 20px;

			br {
				display: none;
			}
		}

		.section-image {
			max-width: 80%;
		}

		.section-content-image {
			display: block;
			padding-top: 0;
			text-align: center;

			.btn-primary {
				margin: 0 0 25px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Discover
\* ------------------------------------------------------------ */

.section-discover {
	padding: 184px 0 146px;
	color: #113f63;

	h2 {
		margin-bottom: 44px;
		color: inherit;
		text-transform: none;
		letter-spacing: 0.1em;
	}

	p {
		margin-bottom: 40px;
		font-size: 22px;
		line-height: 1.35;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-discover {
		padding: 50px 0;

		h2 {
			margin-bottom: 30px;
		}

		p {
			font-size: 18px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Stats
\* ------------------------------------------------------------ */

/* Mobile */

@include breakpoint-mobile {
	.section-stats {
		padding: 20px 0;
	}
}

/* ------------------------------------------------------------ *\
	Section Quote
\* ------------------------------------------------------------ */

.section-quote {
	text-align: center;

	.section-icon {
		display: inline-block;
		width: 96px;
		height: 96px;
		border: 2px solid $color-light;
		border-radius: 50%;
		margin-bottom: 63px;
		line-height: 92px;
		text-align: center;
	}

	h3 {
		margin-bottom: 17px;
		font-size: 40px;
		color: inherit;
		font-style: italic;
		text-transform: none;
		letter-spacing: 0.1em;
	}

	p {
		font-size: 22px;
		line-height: 1.4;
	}

	.section-body {
		padding-bottom: 14px;

		> span {
			display: block;
			margin-bottom: 36px;
			font-size: 21px;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-quote {

		h3 {
			font-size: 30px;
		}

		p {
			font-size: 18px;
		}

		.section-icon {
			width: 80px;
			height: 80px;
			margin-bottom: 35px;
			line-height: 76px;
		}

		.section-body {

			> span {
				font-size: 20px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Treatment
\* ------------------------------------------------------------ */

.section-treatment {
	padding: 116px 0 113px;
	background: #f0efeb;
	color: #0f3f63;

	h2 {
		margin-bottom: 46px;
		color: inherit;
		text-transform: none;
		letter-spacing: 0.1em;
	}

	p {
		font-size: 22px;
		line-height: 1.35;

		& + .btn-primary {
			margin-top: 55px;
		}
	}

	.section-image {
		padding: 33px 14px 0 0;
		text-align: center;
	}

	.section-content {
		padding-left: 24px;
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.section-treatment {

		.section-image {
			padding-right: 0;
		}

		.section-content {
			padding-left: 0;
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-treatment {
		padding: 50px 0;

		.section-image {
			padding: 0;
			margin-bottom: 30px;
		}

		p {

			& + .btn-primary {
				margin-top: 20px;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-treatment {

		h2 {
			margin-bottom: 30px;
		}

		p {
			font-size: 18px;

			& + .btn-primary {
				margin-top: 0;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Save
\* ------------------------------------------------------------ */

.section-save {
	padding: 237px 0 228px;

	h2 {
		margin-bottom: 50px;
		color: inherit;
		text-transform: none;
		letter-spacing: 0.1em;
	}

	p {
		font-size: 22px;
		line-height: 1.35;
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-save {
		padding: 150px 0;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-save {
		padding: 75px 0;

		h2 {
			margin-bottom: 30px;

			br {
				display: none;
			}
		}

		p {
			font-size: 18px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Callout
\* ------------------------------------------------------------ */

.section-callout {
	padding: 125px 0 138px;
	color: #0e4063;
	text-align: center;

	h2 {
		color: inherit;
		text-transform: none;
		letter-spacing: 0.1em;

		& + .btn-primary {
			margin-top: 36px;
		}
	}

	p {
		font-size: 22px;
		line-height: 1.35;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-callout {
		padding: 50px 0;

		h2 {

			& + .btn-primary {
				margin-top: 10px;
			}

			br {
				display: none;
			}
		}

		p {
			font-size: 18px;
		}
	}
}
