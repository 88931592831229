/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
	font-family: 'Avenir';
	src: url('../fonts/Avenir-Heavy.eot');
	src: url('../fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-Heavy.woff2') format('woff2'),
		url('../fonts/Avenir-Heavy.woff') format('woff'),
		url('../fonts/Avenir-Heavy.ttf') format('truetype'),
		url('../fonts/Avenir-Heavy.svg#Avenir-Heavy') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../fonts/Avenir-Roman.eot');
	src: url('../fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-Roman.woff2') format('woff2'),
		url('../fonts/Avenir-Roman.woff') format('woff'),
		url('../fonts/Avenir-Roman.ttf') format('truetype'),
		url('../fonts/Avenir-Roman.svg#Avenir-Roman') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../fonts/Avenir-Oblique.eot');
	src: url('../fonts/Avenir-Oblique.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-Oblique.woff2') format('woff2'),
		url('../fonts/Avenir-Oblique.woff') format('woff'),
		url('../fonts/Avenir-Oblique.ttf') format('truetype'),
		url('../fonts/Avenir-Oblique.svg#Avenir-Oblique') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Tungsten';
	src: url('../fonts/Tungsten-Medium.eot');
	src: url('../fonts/Tungsten-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Tungsten-Medium.woff2') format('woff2'),
		url('../fonts/Tungsten-Medium.woff') format('woff'),
		url('../fonts/Tungsten-Medium.ttf') format('truetype'),
		url('../fonts/Tungsten-Medium.svg#Tungsten-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-Bold.eot');
	src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
		url('../fonts/ProximaNova-Bold.woff') format('woff'),
		url('../fonts/ProximaNova-Bold.ttf') format('truetype'),
		url('../fonts/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}
